<template>
    <div class="rounded shadow relative" @mouseover="pause" @mouseleave="unpause">
        <div class="bg-dish-red rounded-t pt-2 w-64">
            <div class="relative">
                <flickity @init="initialize" ref="flickity" class="w-full -mb-8" :options="{ prevNextButtons: false, pageDots: false, wrapAround: true, autoPlay: true }">
                    <a :href="product.url" target="_blank" rel="noopener noreferrer" class="w-64 px-8" v-for="product in products" :key="product.image">
                        <img @load="reload" :src="product.image">
                    </a>
                </flickity>
            </div>
        </div>
        <div class="bg-white rounded-b flex pt-10 pb-4">
            <a :href="shopLink" target="_blank" rel="noopener noreferrer" class="font-medium text-xl py-1 px-4 bg-dish-red text-white mx-auto block rounded hover:no-underline">Shop Now</a>
        </div>
        <div class="absolute right-0" style="top:40%;">
            <svg class="w-10 h-10 -mr-5 cursor-pointer" @click="next" style="transform: rotate(180deg);" viewBox="0 0 48 48"><path fill="#fff" d="M24 47.5c-6.3 0-12.2-2.4-16.6-6.9S.5 30.3.5 24c0-6.3 2.4-12.2 6.9-16.6S17.7.5 24 .5c6.3 0 12.2 2.4 16.6 6.9s6.9 10.3 6.9 16.6c0 6.3-2.4 12.2-6.9 16.6S30.3 47.5 24 47.5z"/><path fill="#f01446" d="M24 1c3.1 0 6.1.6 9 1.8 2.7 1.2 5.2 2.8 7.3 4.9A22.96 22.96 0 0147 24c0 3.1-.6 6.1-1.8 9-1.2 2.7-2.8 5.2-4.9 7.3A22.96 22.96 0 0124 47c-3.1 0-6.1-.6-9-1.8-2.7-1.2-5.2-2.8-7.3-4.9-2.1-2.1-3.8-4.6-4.9-7.3a23.32 23.32 0 010-18C4 12.3 5.6 9.8 7.7 7.7c2.1-2.1 4.6-3.8 7.3-4.9C17.9 1.6 20.9 1 24 1m0-1C10.7 0 0 10.7 0 24s10.7 24 24 24 24-10.7 24-24S37.3 0 24 0z"/><g fill="#f01446"><path d="M37.7 23.4H12.4l9-9-.8-.8L10.3 24l10.3 10.4.8-.8-9-9h25.3z"/><path d="M20.6 34.8L9.8 24l10.8-10.8 1.2 1.2-8.7 8.7H38v1.7H13.1l8.7 8.7-1.2 1.3zM10.7 24l10 10 .4-.4-9.3-9.3h25.7v-.6H11.7l9.3-9.3-.4-.4-9.9 10z"/></g></svg>
        </div>
        <div class="absolute left-0" style="top:40%;">
            <svg class="w-10 h-10 -ml-5 cursor-pointer" @click="previous" viewBox="0 0 48 48"><path fill="#fff" d="M24 47.5c-6.3 0-12.2-2.4-16.6-6.9S.5 30.3.5 24c0-6.3 2.4-12.2 6.9-16.6S17.7.5 24 .5c6.3 0 12.2 2.4 16.6 6.9s6.9 10.3 6.9 16.6c0 6.3-2.4 12.2-6.9 16.6S30.3 47.5 24 47.5z"/><path fill="#f01446" d="M24 1c3.1 0 6.1.6 9 1.8 2.7 1.2 5.2 2.8 7.3 4.9A22.96 22.96 0 0147 24c0 3.1-.6 6.1-1.8 9-1.2 2.7-2.8 5.2-4.9 7.3A22.96 22.96 0 0124 47c-3.1 0-6.1-.6-9-1.8-2.7-1.2-5.2-2.8-7.3-4.9-2.1-2.1-3.8-4.6-4.9-7.3a23.32 23.32 0 010-18C4 12.3 5.6 9.8 7.7 7.7c2.1-2.1 4.6-3.8 7.3-4.9C17.9 1.6 20.9 1 24 1m0-1C10.7 0 0 10.7 0 24s10.7 24 24 24 24-10.7 24-24S37.3 0 24 0z"/><g fill="#f01446"><path d="M37.7 23.4H12.4l9-9-.8-.8L10.3 24l10.3 10.4.8-.8-9-9h25.3z"/><path d="M20.6 34.8L9.8 24l10.8-10.8 1.2 1.2-8.7 8.7H38v1.7H13.1l8.7 8.7-1.2 1.3zM10.7 24l10 10 .4-.4-9.3-9.3h25.7v-.6H11.7l9.3-9.3-.4-.4-9.9 10z"/></g></svg>
        </div>
    </div>
</template>

<script>
    import Flickity from 'vue-flickity'

    export default {
        components: {
            Flickity,
        },

        data() {
            return {
                flickity: null,
                shopLink: '#',
            }
        },

        methods: {
            initialize(flickity) {
                this.flickity = flickity
                flickity.on('change', index => {
                    this.shopLink = this.products[index].url
                })
            },

            next() {
                this.flickity?.next()
            },

            pause() {
                this.flickity?.pausePlayer()
            },

            previous() {
                this.flickity?.previous()
            },

            reload() {
                this.$refs['flickity'].rerender()
            },

            unpause() {
                this.flickity?.unpausePlayer()
            },
        },

        mounted() {
            this.shopLink = this.products.length > 0 ? this.products[0].url : '#' 
        },

        props: {
            products: {
                type: Array,
                required: true,
            },
        },
    }
</script>

<style src="flickity/dist/flickity.min.css"></style>
