<template>
    <div class="font-sans relative">
		<header class="sticky top-0 z-20">
			<div class="bg-white shadow py-4 h-14 relative z-10 sm:h-16 md:h-auto">
				<div class="container mx-auto px-4 flex flex-col justify-between items-center text-xl uppercase text-gray-600 sm:flex-row md:px-8 lg:text-lg xl:text-xl">
					<a href="https://www.dishformyrv.com">
                        <img class="h-6 sm:h-8 md:h-6 xl:h-8" :src="require('@/assets/dish-outdoors.svg')">
                    </a>
					<a class="mt-4 text-dish-red font-bold no-underline sm:mt-0" :href="phoneUrl">{{ phoneNumber }}</a>
				</div>
			</div>
		</header>

        <div class="container mx-auto px-4 lg:px-32 xl:px-48">
            <img class="mx-auto rounded-b" :src="require('@/assets/gift-guide/Header.jpg')"/>
            <div class="mx-auto py-4 sm:py-12">
                <h2 class="text-center font-bold normal-case text-2xl sm:text-5xl xl:text-4xl">Your one-stop shop for your FAVORITE person!</h2>
                
                <div class="flex flex-col lg:flex-row items-center">
                    <div class="mt-4 flex-grow md:mt-8">
                        <p class="text-base sm:text-xl leading-normal">Be unique this holiday season with the gift of portable satellite entertainment from DISH Outdoors! Every RVer, hunter, fisher, and outdoor enthusiast will love these gifts. Shopping for someone who already has an antenna? Check out the accessories!</p>
                        <p class="text-base sm:text-xl leading-normal mt-4">The DISH Tailgater 4 Bundle or the Winegard Pathway X2 Bundle is a great place to start. Both are automatic and ready to use out of the box. When bundling, you'll already have our newest receiver, the Wally, in ready your cart.</p>
                    </div>
                    <div class="mt-8 flex-shrink-0 lg:-mr-24 lg:ml-16 md:mt-8">
                        <product-slider :products="[
                            { image: require('@/assets/gift-guide/Tailgater-4.png'), url: 'https://www.dishformyrv.com/dish-tailgater-satellite-antenna-bundle-with-wally/' },
                            { image: require('@/assets/gift-guide/X2.png'), url: 'https://www.dishformyrv.com/winegard-pathway-x2-bundle-with-wally/' },
                        ]"/>
                    </div>
                </div>
                
                <div class="flex flex-col lg:flex-row-reverse items-center">
                    <div class="mt-8 md:mt-12">
                        <p class="text-base sm:text-xl leading-normal">Let them record up to 100 hours of their favorite shows with the DVR Upgrade Expansion! It has its own built in power supply, so no extra cords to plug in! You can also watch your recorded programming while on the road without an in-motion antenna with this device.</p>
                        <p class="text-base sm:text-xl leading-normal mt-4">Did you know our Wally Receivers come pre-programmed with apps like Netflix and Game Finder? Enhance their watching experience by adding a Wi-Fi Adapter and the Over-The-Air-Tuner so that they can get their local channels through their Wally.</p>
                    </div>
                    <div class="mt-8 md:mt-12 flex-shrink-0 lg:-ml-24 lg:mr-16">
                        <product-slider :products="[
                            { image: require('@/assets/gift-guide/Wally-DVR.png'), url: 'https://www.dishformyrv.com/1-tb-dvr-upgrade-expansion/' },
                            { image: require('@/assets/gift-guide/WiFi.png'), url: 'https://www.dishformyrv.com/dish-wally-wi-fi-usb-adapter/' },
                            { image: require('@/assets/gift-guide/OTA.png'), url: 'https://www.dishformyrv.com/dish-wally-over-the-air-tuner/' },
                        ]"/>
                    </div>
                </div>

                <div class="flex flex-col lg:flex-row items-center">
                    <div class="mt-8 md:mt-12 flex-grow">
                        <p class="text-base sm:text-xl leading-normal">The TravlFi Journey1 is a Wi-Fi mobile hotspot device that uses Virtual SIM technology to automatically acquire the strongest signal among major carriers. With a wide variety of no contract, Pay As You Go data plans available, this personal hotspot fits the needs of any traveler. Stay connected with your laptop, tablet, streaming devices, and more with the TravlFi Journey1.</p>
                    </div>
                    <div class="mt-8 flex-shrink-0 lg:-mr-24 lg:ml-16 md:mt-8">
                        <product-slider :products="[
                            { image: require('@/assets/gift-guide/Journey-1.png'), url: 'https://www.dishformyrv.com/new-travlfi-journey1-lte-wi-fi-hotspot/' },
                            { image: require('@/assets/gift-guide/Case.png'), url: 'https://www.dishformyrv.com/new-travlfi-journey1-lte-wi-fi-hotspot/' },
                        ]"/>
                    </div>
                </div>

                <div class="flex flex-col lg:flex-row-reverse items-center">
                    <div class="mt-8 md:mt-12 flex-grow">
                        <p class="text-base sm:text-xl leading-normal">The KING or Winegard Tripod Mount will help keep their new antennas off the ground. Along with the KING Quick Release Roof Mount Kit, and the KING Tailgater Window Mount.</p>
                        <p class="text-base sm:text-xl leading-normal mt-4">Both the KING and the Winegard tripod mounts are easy to assemble and disassemble for compact storage. Enjoy temporary mounting with the KING Tailgater Antenna with no-drill mounting plates. With the KING Quick Release Roof Mount Kit, you’ll find convenience in making your mounted antenna portable.</p>
                    </div>
                    <div class="mt-8 md:mt-12 flex-shrink-0 lg:-ml-24 lg:mr-16">
                        <product-slider :products="[
                            { image: require('@/assets/gift-guide/Tripod-Mount.png'), url: 'https://www.dishformyrv.com/king-tripod-mount/' },
                            { image: require('@/assets/gift-guide/Winegard-Mount.png'), url: 'https://www.dishformyrv.com/winegard-tr-1518-tripod-accessory/' },
                            { image: require('@/assets/gift-guide/Quick-Release.png'), url: 'https://www.dishformyrv.com/king-quick-release-roof-mount-kit/' },
                            { image: require('@/assets/gift-guide/Window-Mount.png'), url: 'https://www.dishformyrv.com/king-tailgater-window-mount/' },
                        ]"/>
                    </div>
                </div>
            </div>
        </div>

        <footer class="bg-gray-900 py-16">
            <div class="container mx-auto px-4 text-center">
                <p v-if="state === 'success'" class="text-3xl font-bold text-white sm:text-4xl">We'll be in touch!</p>
                <template v-else>
                    <p class="text-white font-bold text-xl sm:text-2xl">Be the first to know about our the Cyber Weekend Deals!</p>
                    <form @submit.prevent="submitForm" class="mt-4 mx-auto max-w-xs flex justify-center flex-col sm:max-w-md sm:flex-row">
                        <input v-model="email" type="email" autocomplete="email" placeholder="Email Address" class="bg-white rounded border-0 flex-grow shadow-none text-lg h-10 px-4 py-2 leading-normal text-black placeholder:text-gray-700 sm:rounded-r-none sm:text-xl">
                        <button type="submit" class="relative flex-shrink-0 bg-dish-red text-white px-4 h-10 text-base rounded mt-2 sm:mt-0 sm:rounded-l-none sm:text-xl">
                            <span :class="{ 'text-transparent': state === 'loading' }">Sign Me Up</span>
                            <div v-if="state === 'loading'" class="absolute inset-0 flex justify-center items-center">
                                <div class="h-6 w-6 text-white border-4 border-transparent rounded-full animate-spin" style="border-top-color: #fff;"></div>
                            </div>
                        </button>
                    </form>
                </template>
                <p class="text-dish-red font-bold mt-4" v-if="state === 'error'">An unexpected error occurred. Please try again later.</p>
                <p class="text-dish-red font-bold mt-4" v-if="invalidEmail">Invalid Email Address</p>
            </div>
        </footer>
    </div>
</template>

<script>
    import ProductSlider from './ProductSlider'

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const formEndpoint = 'https://api.hsforms.com/submissions/v3/integration/submit/1846077/d39d8dc7-d9e3-4f68-9a70-70f58872ee1c'

    export default {
        components: {
            ProductSlider,
        },

		computed: {
			phoneUrl() {
				return `tel:${this.phoneNumber}`
			},
		},

        data() {
            return {
                email: null,
                invalidEmail: false,
                state: null,

				phoneNumber: '1-800-472-1039',
            }
        },

        methods: {
            submitForm() {
                if (this.state === 'loading' || this.state === 'success') {
                    return
                }

                if (!emailRegex.test(this.email)) {
                    this.invalidEmail = true
                    return
                }

                this.state = 'loading'

                fetch(formEndpoint, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        fields: [
                            { name: 'email', value: this.email },
                        ],
                    }),
                }).then(() => {
                    this.state = 'success'
                }).catch(() => {
                    this.state = 'error'
                })
            },
        },

        watch: {
            email(value) {
                if (this.invalidEmail) {
                    this.invalidEmail = !emailRegex.test(value)
                }
            },
        },
    }
</script>
